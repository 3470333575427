import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { LookupValue } from '@app/_models/lookup-value';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-process',
  templateUrl: './bia-process.component.html',
  styleUrls: ['./bia-process.component.scss']
})
export class BiaProcessComponent implements OnInit {

  paramItem: BIABusinessProcessModel = new BIABusinessProcessModel();
  onUpdateItem: Subject<BIABusinessProcessModel> = new Subject<BIABusinessProcessModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Business Process';

  biaForm!: UntypedFormGroup;

  optionsApplication: Array<any> = new Array<any>();
  optionsImpact: Array<any> = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      processName: ['', Validators.required],
      processApplication: [''],
      impactCategory: [''],
      processDesc: ['']
    });

    this.loadApplicationList();
    this.loadImpactCategoryList();

    this.loadPage();
  }

  get processName(): any {
    return this.biaForm.get('processName');
  }

  get processApplication(): any {
    return this.biaForm.get('processApplication');
  }

  get impactCategory(): any {
    return this.biaForm.get('impactCategory');
  }

  get processDesc(): any {
    return this.biaForm.get('processDesc');
  }

  loadImpactCategoryList(): void {
    this.optionsImpact.push(new LookupValue("1", "Severe"));
    this.optionsImpact.push(new LookupValue("2", "Moderate"));
    this.optionsImpact.push(new LookupValue("3", "Minimal"));
  }

  loadApplicationList(): void {
    this.pageService.modelDto.applications.forEach(app => {
      this.optionsApplication.push(new LookupValue(app.appId.toString(), app.appName))
    });
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(1);
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.processName.setValue(this.paramItem.processName);
    this.processApplication.setValue(this.paramItem.appId.toString());
    this.impactCategory.setValue(this.paramItem.impactCategoryId.toString());
    this.processDesc.setValue(this.paramItem.processDesc);

    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.processName = this.processName.value;
    this.paramItem.appId = +this.processApplication.value;
    this.paramItem.impactCategoryId = +this.impactCategory.value;
    this.paramItem.processDesc = this.processDesc.value;

    this.pageService.modelDto.applications.forEach(app => {
      if (app.appId == this.paramItem.appId) {
        this.paramItem.application = app;
      }
    });

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}

