import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IAppStepComponent, IAppStepFormComponent } from '@app/_models/iappstep-component';
import { AppEditStepFormService } from '../app-edit/app-edit-step-form.service';
import { AppListPageService } from '../app-list/app-list-page.service';

@Component({
  selector: 'app-app-comments',
  templateUrl: './app-comments.component.html',
  styleUrls: ['./app-comments.component.scss'],
  providers: [ AppEditStepFormService ]
})
export class AppCommentsComponent implements OnInit, IAppStepComponent, IAppStepFormComponent {

  pageIndex = 4;
  isSubmitted = false;
  saving = false;

  applicationForm!: UntypedFormGroup;
  
  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public stepService: AppEditStepFormService,
    public pageService: AppListPageService) { }

  ngOnInit(): void {

    this.applicationForm = this.formBuilder.group({
      appComments: ['']
    });

    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
    this.loadPage();
  }

  public loadPage(): void {
    this.appComments.setValue(this.pageService.modelDto.application.appComments);
  }

  get appComments(): any {
    return this.applicationForm.get('appComments');
  }

  public isValid(): boolean {
    return this.applicationForm.valid;
  }

  public loadDto(): void {
    this.pageService.modelDto.application.appComments = this.appComments.value;
  }

}
