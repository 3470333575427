import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserBoosterModel } from '@app/wellness/_models/user-booster-model';
import { DialogResult } from '@app/_models/dialog-result-model';
import { FileUploadDocumentModel } from '@app/_models/file-upload-document-model';
import { IMyOptions, MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';

@Component({
  selector: 'app-booster-card',
  templateUrl: './booster-card.component.html',
  styleUrls: ['./booster-card.component.scss']
})
export class BoosterCardComponent implements OnInit {

  private confirmModal!: MDBModalRef;
  private fileUpload!: FileUploadDocumentModel;

  isSubmitted = false;
  saving = false;
  showBoosterForm = false;
  addButtonText = "ADD";

  public datePickerOptions!: IMyOptions;
  public optionsVaccineName = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  boosterForm!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    private toastService: ToastService,
    public pageService: UserVaccinePageService) { }

  ngOnInit(): void {

    this.datePickerOptions = {
      dateFormat: 'mmmm dd, yyyy',
      useDateObject: true,
      editableDateField: false,
      closeAfterSelect: true,
      showClearDateBtn: false,
    };

    this.optionsVaccineName.push({ value: 1, label: 'Pfizer-BioNTech' });
    this.optionsVaccineName.push({ value: 2, label: 'Moderna' });
    this.optionsVaccineName.push({ value: 3, label: 'Johnson & Johnson' });
    this.optionsVaccineName.push({ value: 4, label: 'Other vaccine listed for emergency use by WHO' });

    this.boosterForm = this.formBuilder.group({
      vaccineNameId: ['', Validators.required],
      doseDt: ['', Validators.required],
    },
      { validator: [this.futureDate('doseDt')] });
  }

  get vaccineNameId(): any {
    return this.boosterForm.get('vaccineNameId');
  }

  get doseDt(): any {
    return this.boosterForm.get('doseDt');
  }

  public getFileType(): string {
    return this.pageService.getFileType();
  }

  public addButtonClass(): string {
    return this.showBoosterForm ? 'fa fa-minus' : 'fa fa-plus';
  }

  onBoosterShow(): void {
    this.showBoosterForm = !this.showBoosterForm;
    this.addButtonText = (this.showBoosterForm) ? "CANCEL" : "ADD";    
  }

  onDownloadFile(userBoosterId: number) {
    this.pageService.downloadBoosterFile(userBoosterId);
  }

  onUploadCard(): void {

    const modalFormHeader = 'Upload Booster Card';

    this.fileUpload = new FileUploadDocumentModel();

    const ud = this.modalService.show(FileUploadComponent, {
      class: 'modal-md',
      data: {
        modalTitle: modalFormHeader,
        document: this.fileUpload
      },
    });

    ud.content.uploadedDocument.subscribe(
      (result: FileUploadDocumentModel) => {
        if (result.uploadResult) {

          this.fileUpload = result;
          this.saveBooster();
        }
      }
    );
  }

  onDelete(userBoosterId: number, index: number) {

    const msg = 'Are you sure to delete this record?';

    this.confirmModal = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      data: {
        title: 'Please Confirm',
        body: msg,
        confirmAction: { label: 'Delete' },
        cancelAction: { label: 'Cancel' },
      },
    });

    this.confirmModal.content.dialogResult.subscribe(

      (dialogResult: DialogResult) => {

        if (dialogResult.action.toLowerCase() === 'confirm') {

          if (userBoosterId === 0) {

            this.pageService.vaccine.boosters.splice(index, 1);

            this.toastService.success('The booster vaccine record has been successfully deleted.', null, this.toastMessageOptions)

          } else {

            this.pageService.deleteBooster(userBoosterId).subscribe(e => {

              if (e.statusId >= 0) {

                this.pageService.vaccine.boosters.splice(index, 1);

                this.toastService.success(e.message, null, this.toastMessageOptions)

              } else {

                this.toastService.error(e.message, null, this.toastMessageOptions)
              }

              console.log("vaccine save end");
            });
          }
        }
      }

    );

  }

  private saveBooster() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.boosterForm.valid) {
      return;
    }

    this.saving = true;

    let booster = new UserBoosterModel();

    booster.userId = +this.pageService.user.empId;
    booster.vaccineNameId = +this.vaccineNameId.value;
    booster.doseDt = new Date(this.doseDt.value);

    booster.document.documentId = 0;
    booster.document.userId = +this.pageService.user.empId;
    booster.document.createdBy = +this.pageService.user.empId;
    booster.document.documentTypeId = 3;
    booster.document.fileName = this.fileUpload.fileName;
    booster.document.fileExt = this.fileUpload.fileExt;

    booster.file = this.fileUpload.file;

    console.log("booster save start");

    this.pageService.addBooster(booster).subscribe(e => {

      if (e.statusId >= 0) {

        this.pageService.vaccine.boosters.push(booster);

        this.showBoosterForm = false;
        this.addButtonText = "ADD"; 

        this.isSubmitted = false;

        this.toastService.success(e.message, null, this.toastMessageOptions)

      } else {

        this.toastService.error(e.message, null, this.toastMessageOptions)
      }

      this.saving = false;

      console.log("vaccine save end");
    })
  }

  futureDate(from: string) {

    return (group: UntypedFormGroup): { [key: string]: any } => {

      let f = group.controls[from];

      const fromDate = new Date(f.value);

      if (fromDate > new Date()) {
        return { futureDate: "future dosage date" };
      }

      return {};
    }
  }

}
function FileUploadComponent(FileUploadComponent: any, arg1: { class: string; data: { modalTitle: string; document: FileUploadDocumentModel; }; }) {
  throw new Error('Function not implemented.');
}

function ConfirmModalComponent(ConfirmModalComponent: any, arg1: { class: string; data: { title: string; body: string; confirmAction: { label: string; }; cancelAction: { label: string; }; }; }): MDBModalRef {
  throw new Error('Function not implemented.');
}

