
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark">
    <mdb-navbar-brand>&nbsp;</mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateBia()">Business Impact Analysis</a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateApplication()">Application Inventory</a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateAdmin()">Administration</a>
        </li>

        <!--<li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect href="{{reportUrl}}" target="_blank">
            Reports
          </a>
        </li>-->

        <li *ngIf="isAdmin()" class="nav-item dropdown">
          <div mdbDropdown class="dropdown">
            <button class="btn btn-primary dropdown-toggle" style="background-color: #001c71 !important;"
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    mdbDropdownToggle>
              &nbsp;Reports
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="javascript:void(0)" (click)="navigateReport()">&nbsp;Bia Overall Report</a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:void(0)" (click)="navigateReport2()">&nbsp;Downtime with Contacts Report</a>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </links>
</mdb-navbar>
