import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BIAContactModel } from '@app/bia/_models/bia-contact-model';
import { EmployeePickerComponent } from '@app/_components/employee-picker/employee-picker.component';
import { EmployeeModel } from '@app/_models/employee-model';
import { LookupValue } from '@app/_models/lookup-value';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-contact-edit',
  templateUrl: './bia-contact-edit.component.html',
  styleUrls: ['./bia-contact-edit.component.scss']
})
export class BiaContactEditComponent implements OnInit {

  paramItem: BIAContactModel = new BIAContactModel();
  onUpdateItem: Subject<BIAContactModel> = new Subject<BIAContactModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Point of Contact';

  biaForm!: UntypedFormGroup;

  optionsContactType: Array<any> = new Array<any>();
  optionsContact: Observable<any[]> = new Observable<any[]>();

  employeeList: Array<EmployeeModel> = new Array<EmployeeModel>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      contactTypeId: ['', Validators.required],
      contactId: ['', Validators.required],
      criticalProcess: [Boolean],
    });

    this.loadContactTypeList();
    this.loadPage();
  }

  get contactType(): any {
    return this.biaForm.get('contactTypeId');
  }

  get contact(): any {
    return this.biaForm.get('contactId');
  }

  get criticalProcess(): any {
    return this.biaForm.get('criticalProcess');
  }

  loadContactTypeList(): void {
    this.pageService.modelDto.contactTypes.forEach(item => {
      this.optionsContactType.push(new LookupValue(item.pocTypeId.toString(), item.pocTypeName))
    });
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.contactType.setValue(this.paramItem.pocTypeId.toString());
    this.criticalProcess.setValue(this.paramItem.criticalProcess);

    if (this.paramItem.employee != null) {
      this.contact.setValue(this.paramItem.employee.fullName);
    }

    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  onEmployeeSelect(): void {
    const pp = this.modalService.show(EmployeePickerComponent, {
      class: 'modal-md',
      data: {
        lookupValueType: 'Employee',
      },
    });

    pp.content.returnElements.subscribe((elements: any[]) => {
      elements.forEach(element => {

        this.paramItem.empId = element.value;
        this.contact.setValue(element.label);

        this.paramItem.employee = element.employee;
        this.paramItem.employee.empId = element.value;
        this.paramItem.employee.fullName = element.label;
      });
    });
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.pocTypeId = +this.contactType.value;
    this.paramItem.criticalProcess = this.criticalProcess.value;

    this.pageService.modelDto.contactTypes.forEach(item => {
      if (item.pocTypeId == this.paramItem.pocTypeId) {
        this.paramItem.contactType = item;
      }
    });

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}
