import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMyOptions, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { DocumentManagementComponent } from '../document-management/document-management.component';
import { formatDate } from '@angular/common';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';
import { AccommodationTypeModel } from '@app/wellness/_models/accommodation-type-model';

@Component({
  selector: 'app-vaccine-card',
  templateUrl: './vaccine-card.component.html',
  styleUrls: ['./vaccine-card.component.scss']
})
export class VaccineCardComponent implements OnInit {

  isSubmitted = false;  
  pageTitle = 'vaccine card';
  saving = false;
  hideElement = false;

  showSecondDose = true;
  showFileUpload = false;
  showBooster = false;

  doseDate1Name = 'Date of First Dose';
  doseDate2Name = 'Date of Second Dose';
  doseDate1ShortClass = '';
  doseDate2ShortClass = '';
  showDoseDate1WarningText = false;
  showDoseDate2WarningText = false;
  doseDate1WarningText = '';
  doseDate2WarningText = '';

  vaccineForm!: UntypedFormGroup;

  public datePickerOptions!: IMyOptions;
  public optionsVaccineName = new Array<any>();
  public statusOptions = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    private toastService: ToastService,
    public pageService: UserVaccinePageService) { }

  ngOnInit(): void {

    this.datePickerOptions = {
      dateFormat: 'mmmm dd, yyyy',
      useDateObject: true,
      editableDateField: false,
      closeAfterSelect: true,
      showClearDateBtn: false,
    };

    this.optionsVaccineName.push({ value: 1, label: 'Pfizer-BioNTech' });
    this.optionsVaccineName.push({ value: 2, label: 'Moderna' });
    this.optionsVaccineName.push({ value: 3, label: 'Johnson & Johnson' });
    this.optionsVaccineName.push({ value: 4, label: 'Other vaccine listed for emergency use by WHO' });

    this.statusOptions.push({ value: 2, label: 'Success' });

    this.vaccineForm = this.formBuilder.group({
      vaccineNameId: ['', Validators.required],
      doseDt1: ['', Validators.required],
      doseDt2: [''],
      statusId: ['']
    },
      { validator: [this.dateLessThan('doseDt1', 'doseDt2'), this.futureDate('doseDt1', 'doseDt2')] });

    if (this.pageService.cached) {
      this.loadPage();
    }
  }

  public loadPage(): void {

    this.showFileUpload = this.pageService.cardUploaded;

    if (this.pageService.cardMissing || this.showFileUpload) {

      this.vaccineNameId.setValue(this.pageService.vaccine.vaccineNameId);

      if (this.pageService.vaccine.doseDt1 != null) {
        this.doseDt1.setValue(formatDate(this.pageService.vaccine.doseDt1, 'MMMM d, y', 'en'));
        this.setDoseDate1Warning(new Date(this.pageService.vaccine.doseDt1));
      }

      if (this.pageService.vaccine.doseDt2 != null) {
        this.doseDt2.setValue(formatDate(this.pageService.vaccine.doseDt2, 'MMMM d, y', 'en'));
        this.setDoseDate2Warning(new Date(this.pageService.vaccine.doseDt2));
      }

      if (this.showFileUpload) {

        this.setPageReadOnly();

        this.pageTitle = (this.pageService.adminView) ? this.pageService.vaccine.user.fullName : 'Vaccination';

      } else if (this.pageService.cardMissing) {

        this.setDoseDateUI();
        this.pageTitle = "Upload Vaccination Card";
      }
    }

    this.showSecondDose = this.pageService.showTwoDoses(this.pageService.vaccine.vaccineNameId);
  }

  get vaccineNameId(): any {
    return this.vaccineForm.get('vaccineNameId');
  }

  get doseDt1(): any {
    return this.vaccineForm.get('doseDt1');
  }

  get doseDt2(): any {
    return this.vaccineForm.get('doseDt2');
  }

  get statusId(): any {
    return this.vaccineForm.get('statusId');
  }

  public getFileType(): string {
    return this.pageService.getFileType();
  }

  public onDownloadFile() {
    this.pageService.downloadFile();
  }

  public onDoseDate1Change(e: any) {

    let doseDate = new Date(e.date.year, e.date.month - 1, e.date.day)
    this.setDoseDate1Warning(doseDate);
  }

  public onDoseDate2Change(e: any) {

    let doseDate = new Date(e.date.year, e.date.month - 1, e.date.day)
    this.setDoseDate2Warning(doseDate);
  }

  private resetDoseDates() {
    this.doseDt1.setValue(null);
    this.showDoseDate1WarningText = false;
    this.doseDate1WarningText = '';
    this.doseDate1ShortClass = '';

    this.doseDt2.setValue(null);
    this.showDoseDate2WarningText = false;
    this.doseDate2WarningText = '';
    this.doseDate2ShortClass = '';
  }

  public setDoseDate1Warning(doseDate: Date) {

    const nameId = this.vaccineNameId.value;

    if (this.pageService.isSecondDoseRequired(nameId)) {
      return;
    }

    const doseDate1Name = (this.showSecondDose) ? 'Date of First Dose' : 'Date of Dose';

    const warningMsg = this.pageService.getDoseDate1Warning(doseDate);

    if (warningMsg.length > 0) {
      this.doseDate1Name = doseDate1Name + " *";
      this.doseDate1WarningText = '* ' + warningMsg;
      this.showDoseDate1WarningText = true;

    } else {

      this.doseDate1WarningText = '';
      this.doseDate1ShortClass = '';
      this.doseDate1Name = doseDate1Name;

      this.showDoseDate1WarningText = false;
    }

  }

  setDoseDate2Warning(doseDate: Date) {

    const warningMsg = this.pageService.getDoseDate2Warning(doseDate);

    if (warningMsg.length > 0) {

      this.doseDate2Name = "Date of Second Dose *";
      this.doseDate2WarningText = '* ' + warningMsg;
      this.showDoseDate2WarningText = true;

    } else {

      this.doseDate2WarningText = '';
      this.doseDate2ShortClass = '';
      this.doseDate2Name = "Date of Second Dose";

      this.showDoseDate2WarningText = false;
    }
  }

  private setDoseDateUI() {

    const nameId = this.vaccineNameId.value;
    this.showSecondDose = this.pageService.showTwoDoses(nameId);

    this.doseDate1Name = (this.showSecondDose) ? 'Date of First Dose' : 'Date of Dose';

    if (!this.pageService.isSecondDoseRequired(nameId)) {
      this.doseDt2.setValue(null);
    }
  }

  private clearDoseDateValidators() {
    this.doseDt2.clearValidators();
    this.doseDt2.updateValueAndValidity();
  }

  private setDoseDateValidators() {

    const nameId = this.vaccineNameId.value;
    this.showSecondDose = this.pageService.showTwoDoses(nameId);

    this.doseDate1Name = (this.showSecondDose) ? 'Date of First Dose' : 'Date of Dose';

    if (this.pageService.isSecondDoseRequired(nameId)) {
      this.doseDt2.setValidators(Validators.required);
    } else {
      this.doseDt2.setValue(null);
      this.doseDt2.clearValidators();
    }

    this.doseDt2.updateValueAndValidity();
  }

  public onVaccineChange() {
    this.resetDoseDates();
    this.setDoseDateUI();
  }

  public save(isFinal: boolean) {

    if (this.saving) {
     return; 
    }

    this.isSubmitted = true;

    if (isFinal) {
      this.setDoseDateValidators();
    } else {
      this.clearDoseDateValidators();
    }

    if (!this.vaccineForm.valid) {
      return;
    }
  
    this.pageService.vaccine.vaccineNameId = +this.vaccineNameId.value;
    this.pageService.vaccine.doseDt1 = new Date(this.doseDt1.value);

    let doseDate2: Date | null = null;

    if (this.pageService.showTwoDoses(this.pageService.vaccine.vaccineNameId)) {
      if (this.doseDt2.value != null) {
        doseDate2 = new Date(this.doseDt2.value);
      }
    }

    this.pageService.vaccine.doseDt2 = doseDate2;

    // reset accommodation fields

    this.pageService.vaccine.accommodationTypeId = 0;
    this.pageService.vaccine.accommodationType = new AccommodationTypeModel();

    if (isFinal) {
      this.pageService.vaccine.statusId = 2;
    }

    this.saving = true;

    console.log("vaccine save start");

    this.pageService.saveVaccine(isFinal).subscribe(e => {

      if (e.statusId >= 0) {

        if (isFinal) {

          this.pageTitle = 'Vaccination';

          this.setPageReadOnly();

          this.showFileUpload = true;

        } else {

          this.pageTitle = "Upload Vaccination Card";

        }

        this.toastService.success(e.message, null, this.toastMessageOptions)

      } else {

        this.toastService.error(e.message, null, this.toastMessageOptions)
      }

      this.saving = false;

      console.log("vaccine save end");
    })
  }

  private setPageReadOnly() {

    this.vaccineNameId.disable();
    this.doseDt1.disable();

    if (this.pageService.showTwoDoses(this.pageService.vaccine.vaccineNameId)) {
      this.doseDt2.disable();
    }

    this.statusId.setValue(this.pageService.vaccine.statusId);
    this.statusId.disable();
  }

  onSaveCard(): void {

    this.save(false);
  }

  onUploadCard(): void {

    this.setDoseDateValidators();

    if (!this.vaccineForm.valid) {
      this.isSubmitted = true;
      return;
    }

    const docTypeId = 1;
    const modalFormHeader = 'Upload Vaccination Card';

    this.pageService.vaccine.document.documentId = 0;
    this.pageService.vaccine.document.userId = +this.pageService.user.empId;
    this.pageService.vaccine.document.documentTypeId = docTypeId;

    const ud = this.modalService.show(DocumentManagementComponent, {
      class: 'modal-md',
      data: {
        modalTitle: modalFormHeader
      },
    });

    ud.content.uploadedDocument.subscribe(
      (isUploaded: boolean) => {
        if (isUploaded) {
          this.save(true);
        }
      }
    );
  }

  futureDate(from: string, to: string) {

    return (group: UntypedFormGroup): { [key: string]: any } => {

      let nameId = group.controls['vaccineNameId'].value;
      let twoDose = this.pageService.showTwoDoses(nameId);

      let f = group.controls[from];
      let t = group.controls[to];

      const fromDate = new Date(f.value);
      
      if (fromDate > new Date()) {
        return {
          futureDate: "future dosage date"
        };
      }

      if (twoDose) {
        const toDate = new Date(t.value);
        if (toDate > new Date()) {
          return {
            futureDate: "future dosage date"
          };
        }
      }

      return {};
    }
  }

  dateLessThan(from: string, to: string) {

    return (group: UntypedFormGroup): { [key: string]: any } => {

      let nameId = group.controls['vaccineNameId'].value;

      if (!this.pageService.isSecondDoseRequired(nameId)) {
        return {};
      }

      let f = group.controls[from];
      let t = group.controls[to];

      if (f.value == null) {
        return {};
      }

      if (t.value == null) {
        return {};
      }

      const fromDate = new Date(f.value);
      const toDate = new Date(t.value);

      if (fromDate >= toDate) {
        return {
          dates: "first dose date should be less than second dose date"
        };
      }

      return {};
    }
  }



}
