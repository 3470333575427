import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@app/_services/config.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BIADto } from '../_models/bia-dto';
import { BIAModel } from '../_models/bia-model';

@Injectable({
  providedIn: 'root'
})
export class BIAService {
  private rptParameterSource = new BehaviorSubject<string>('');
  currentRptParameter = this.rptParameterSource.asObservable();

  changeRptParameter(parameter: string) {
    this.rptParameterSource.next(parameter);
  }

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  
  constructor(private http: HttpClient) { }

  public getBIA(biaId: number): Observable<BIADto> {

    let requestURL = APP_CONFIG.apiDomain + '/bia/' + biaId.toString();

    return this.http
      .get<BIADto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getBIAList(): Observable<Array<BIAModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/bia/list';

    return this.http
      .get<Array<BIAModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getBIAListByUser(empId: string): Observable<Array<BIAModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/bia/userlist/' + empId;

    return this.http
      .get<Array<BIAModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public saveBIA(model: BIAModel): Observable<BIAModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/bia/save';

    return this.http.post<BIAModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public deleteBIA(model: BIAModel): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/bia/delete';

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public deleteBIAItem(model: any, itemRoute: string): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/bia/delete-' + itemRoute;

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}
