import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppContactModel } from '@app/_models/app-contact-model';
import { LookupValue } from '@app/_models/lookup-value';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, Subject } from 'rxjs';
import { AppListPageService } from '../app-list/app-list-page.service';

import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';
import { EmployeeModel } from '@app/_models/employee-model';

@Component({
  selector: 'app-app-contact-edit',
  templateUrl: './app-contact-edit.component.html',
  styleUrls: ['./app-contact-edit.component.scss']
})
export class AppContactEditComponent implements OnInit {

  paramItem: AppContactModel = new AppContactModel();
  onUpdateItem: Subject<AppContactModel> = new Subject<AppContactModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Point of Contact';

  readOnly = false;
  closeButtonLabel = 'Cancel';

  applicationForm!: UntypedFormGroup;

  optionsContactType: Array<any> = new Array<any>();

  optionsContact: Observable<any[]> = new Observable<any[]>();
  employeeList: Array<EmployeeModel> = new Array<EmployeeModel>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    public pageService: AppListPageService) { }

  ngOnInit(): void {

    this.applicationForm = this.formBuilder.group({
      contactType: ['', Validators.required],
      appContact: ['', Validators.required]
    });

    this.loadContactTypeList();
    this.loadPage();
    this.setReadOnly();
  }

  get contactType(): any {
    return this.applicationForm.get('contactType');
  }

  get contact(): any {
    return this.applicationForm.get('appContact');

  }

  loadContactTypeList(): void {
    this.pageService.modelDto.contactTypes.forEach(ct => {
      this.optionsContactType.push(new LookupValue(ct.contactTypeId.toString(), ct.contactType))
    });
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(2);
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  setReadOnly(): void {
    if (this.readOnly) {
      this.contactType.disable();
      this.closeButtonLabel = 'Close';
    }
  }

  public loadPage(): void {

    this.contactType.setValue(this.paramItem.contactTypeId.toString());

    if (this.paramItem.employee != null) {
      this.contact.setValue(this.paramItem.employee.fullName);
    }

    if (this.readOnly) {
      this.modalTitle += ' [View]';
    }
    else if (this.paramItem.appId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  onEmployeeSelect(): void {

    if (this.readOnly) {
      return;
    }

    const pp = this.modalService.show(EmployeePickerComponent, {
      class: 'modal-md',
      data: {
        lookupValueType: 'Employee',
      },
    });

    pp.content.returnElements.subscribe((elements: any[]) => {
      elements.forEach(element => {

        this.paramItem.empId = element.value;
        this.contact.setValue(element.label);

        this.paramItem.employee = element.employee;
        this.paramItem.employee.empId = element.value;
        this.paramItem.employee.fullName = element.label;
      });
    });
  }


  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.applicationForm.valid) {
      return;
    }

    this.paramItem.contactTypeId = +this.contactType.value;

    this.pageService.modelDto.contactTypes.forEach(ct => {
      if (ct.contactTypeId == this.paramItem.contactTypeId) {
        this.paramItem.contactType = ct;
      }
    });

    this.onUpdateItem.next(this.paramItem);
    this.modalRef.hide();
  }
}
