import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { DocumentManagementComponent } from '../document-management/document-management.component';
import { Router } from '@angular/router';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';
import { VaccineTypeModel } from '@app/wellness/_models/vaccine-type-model';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss']
})
export class AccommodationComponent implements OnInit {

  isSubmitted = false;
  saving = false;
  showFormAll = false;
  showFileUpload = false;
  pageTitle = 'BIA Administration';
  typeName = '';
  templateName = '';

  public statusOptions = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  requestForm!: UntypedFormGroup;

  public optionsType = new Array<any>();

  constructor(private formBuilder: UntypedFormBuilder,
    public router: Router,
    private modalService: MDBModalService,
    private toastService: ToastService,
    public pageService: UserVaccinePageService) { }

  ngOnInit(): void {

    this.optionsType.push({ value: 1, label: 'Cherwell Asset/Resources Export' });
    this.optionsType.push({ value: 2, label: 'Cherwell Asset/Resources Import' });

    this.statusOptions = this.pageService.getStatusOptions();
    this.statusOptions.push({ value: 0, label: 'Not Submitted' });

    this.requestForm = this.formBuilder.group({
      typeId: ['', Validators.required],
      statusId: ['']
    });

    if (this.pageService.cached) {
      this.loadPage();
    }
  }

  public loadPage(): void {

    this.showFormAll = this.pageService.vaccine.accommodationTypeId > 0;

    if (this.showFormAll) {

      this.pageTitle = (this.pageService.adminView) ? this.pageService.vaccine.user.fullName : 'View Accommodation';
      
      const typeId = this.pageService.vaccine.accommodationTypeId;
      this.typeId.setValue(typeId);

      if (typeId === 1) {
        this.showReligious();
      } else if (typeId === 2) {
        this.showMedical();
      } 

      this.showFileUpload = true;
    }

    const statusId = this.pageService.vaccine.statusId;
    this.statusId.setValue(statusId);
    this.statusId.disable();

    if (this.pageService.vaccine.userVaccineId > 0){
      this.typeId.disable();
    }    
  }

  get typeId(): any {
    return this.requestForm.get('typeId');
  }

  get statusId(): any {
    return this.requestForm.get('statusId');
  }

  private showMedical() {
    this.showFormAll = true;
    this.typeName = 'Health Care Provider Accommodation Assessment';
    this.statusId.setValue(0);
    this.templateName = 'Medical Accommodation Request Form.pdf';
  }

  private showReligious() {
    this.showFormAll = true;
    this.typeName = 'Religious Accommodation Request';
    this.statusId.setValue(0);
    this.templateName = 'Religious Accommodation Request Form.pdf';
  }

  public onDownloadNewFile() {
    this.pageService.downloadNewFile();
  }

  public onDownloadFile() {
    this.pageService.downloadFile();
  }
    
  public getFileType(): string {
    return this.pageService.getFileType();
  }
  
  public onTypeChange(event: any) {

    if (event !== undefined) {

      const typeId = +event.value;

      if (typeId === 1) {
        this.showReligious();
      } else if (typeId === 2) {
        this.showMedical();
      } 
    }
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.requestForm.valid) {
      return;
    }
    
    this.pageService.vaccine.accommodationTypeId = +this.typeId.value;
    this.pageService.vaccine.statusId = 1; // pending

    // reset vaccine card fields

    this.pageService.vaccine.vaccineNameId = 0;
    this.pageService.vaccine.vaccineName = new VaccineTypeModel();
    this.pageService.vaccine.doseDt1 = null;
    this.pageService.vaccine.doseDt2 = null;

    this.saving = true;

    this.pageService.saveVaccine(true).subscribe(e => {

      if (e.statusId >= 0) {

        this.pageTitle = 'View Accommodation';
        this.statusId.setValue(this.pageService.vaccine.statusId);
        this.statusId.disable();

        this.toastService.success(e.message, null, this.toastMessageOptions)

      } else {
        this.toastService.error(e.message, null, this.toastMessageOptions)
      }

      this.saving = false;
    })
  }

  public onDownloadForm() {
  }

  public onUploadForm(): void {

    const docTypeId = 2;
    const modalFormHeader = 'Upload Accommodation Form';

    this.pageService.vaccine.document.documentId = 0;
    this.pageService.vaccine.document.userId = + this.pageService.user.empId;
    this.pageService.vaccine.document.documentTypeId = docTypeId;

    const ud = this.modalService.show(DocumentManagementComponent, {
      class: 'modal-md',
      data: {
        modalTitle: modalFormHeader
      },
    });

    ud.content.uploadedDocument.subscribe(
      (isUploaded: boolean) => {
        if (isUploaded) {
          this.showFileUpload = isUploaded;
          this.save();
        }
      }
    );

  }
}

