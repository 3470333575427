import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-downtime',
  templateUrl: './bia-downtime.component.html',
  styleUrls: ['./bia-downtime.component.scss']
})
export class BiaDowntimeComponent implements OnInit {

  paramProcess: BIABusinessProcessModel = new BIABusinessProcessModel();
  onUpdateProcess: Subject<BIABusinessProcessModel> = new Subject<BIABusinessProcessModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Estimated Downtime';

  biaForm!: UntypedFormGroup;

  optionsApplication: Array<any> = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      mtd: ['', Validators.required],
      mtdDriver: [''],
      rto: ['', Validators.required],
      rtoDriver: [''],
      rpo: ['', Validators.required],
      rpoDriver: [''],
    });

    this.loadPage();
  }

  get mtd(): any {
    return this.biaForm.get('mtd');
  }

  get mtdDriver(): any {
    return this.biaForm.get('mtdDriver');
  }

  get rto(): any {
    return this.biaForm.get('rto');
  }

  get rtoDriver(): any {
    return this.biaForm.get('rtoDriver');
  }

  get rpo(): any {
    return this.biaForm.get('rpo');
  }

  get rpoDriver(): any {
    return this.biaForm.get('rpoDriver');
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(1);
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.mtd.setValue(this.paramProcess.mtdEstimate);
    this.mtdDriver.setValue(this.paramProcess.mtdDriver);

    this.rto.setValue(this.paramProcess.rtoEstimate);
    this.rtoDriver.setValue(this.paramProcess.rtoDriver);

    this.rpo.setValue(this.paramProcess.rpoEstimate);
    this.rpoDriver.setValue(this.paramProcess.rpoDriver);
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramProcess.mtdEstimate = +this.mtd.value;
    this.paramProcess.mtdDriver = this.mtdDriver.value;
    this.paramProcess.rtoEstimate = +this.rto.value;
    this.paramProcess.rtoDriver = this.rtoDriver.value;
    this.paramProcess.rpoEstimate = +this.rpo.value;
    this.paramProcess.rpoDriver = this.rpoDriver.value;

    this.onUpdateProcess.next(this.paramProcess);

    this.modalRef.hide();
  }

}


