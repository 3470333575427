import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { FileUploadDocumentModel } from '@app/_models/file-upload-document-model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isSubmitted = false;
  isNewDocument = false;
  fileDropped = false;

  modalTitle!: string;
  document!: FileUploadDocumentModel;

  docForm!: UntypedFormGroup;

  files: File[] = [];

  uploadedDocument: Subject<FileUploadDocumentModel> = new Subject<FileUploadDocumentModel>();

  constructor(
    public modalRefDocument: MDBModalRef,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {

    this.docForm = this.formBuilder.group({
      documentName: ['', [Validators.required]],
      uploadedDate: [Date]
    });    

    this.isNewDocument = this.document.isNew;
    
    const uploadedOn = this.document.createdDt == null ? null : formatDate(this.document.createdDt, 'MMM d, y', 'en');
    this.uploadedDate.setValue(uploadedOn);

    if (this.document.fileName) {
      this.fileName.setValue(this.document.fileName);
    }
  }

  ngAfterViewInit(): void { 

  }

  get fileName(): any {
    return this.docForm.get('documentName');
  }

  get uploadedDate(): any {
    return this.docForm.get('uploadedDate');
  }

  onCancel() {
    this.uploadedDocument.next(this.document);
    this.modalRefDocument.hide();
  }


  public onSubmit() {

    this.isSubmitted = true;

    if (!this.docForm.valid) {
      return;
    }

    this.document.uploadResult = true;

    this.uploadedDocument.next(this.document);
    this.modalRefDocument.hide();
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onDropFile(event: any) {
    console.log(event);

    this.files.splice(0, 0, ...event.addedFiles);

    const fileName = this.files[0].name.replace("[\\\\/:*?\"<>|]", "_");

    this.document.fileName = fileName;

    const extension = fileName.split('.').pop();

    if (extension != undefined) {
      this.document.fileExt = extension;
    }

    const uploadDate =  formatDate(new Date(), 'MMM d, y', 'en');

    this.uploadedDate.setValue(uploadDate);
    this.fileName.setValue( this.document.fileName);

    this.document.file = this.files[0];
    this.files = [];

    this.fileDropped = true;
  }
}
