import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { LookupValue } from '@app/_models/lookup-value';
import { IOption, MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
   selector: 'app-bia-recovery-order-edit',
   templateUrl: './bia-recovery-order-edit.component.html',
   styleUrls: ['./bia-recovery-order-edit.component.scss']
})
export class BiaRecoveryOrderEditComponent implements OnInit {

  paramItem: BIAResourceModel = new BIAResourceModel();
  onUpdateItem: Subject<BIAResourceModel> = new Subject<BIAResourceModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Recovery Order';
  typeLabel = 'Type';

  biaForm!: UntypedFormGroup;

  optionsSortOrder: Array<IOption> = new Array<IOption>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      recoveryPriority: [''],
      resourceName: [''],
      rto: [''],
      rtodesc: [''],
      sortOrder: ['', Validators.required],
    });

    this.loadSortOrderList();
    this.loadPage();
  }

  get recoveryPriority(): any {
    return this.biaForm.get('recoveryPriority');
  }

  get resourceName(): any {
    return this.biaForm.get('resourceName');
  }

  get rto(): any {
    return this.biaForm.get('rto');
  }

  get rtodesc(): any {
    return this.biaForm.get('rtodesc');
  }

  get sortOrder(): any {
    return this.biaForm.get('sortOrder');
  }

  loadSortOrderList(): void {

    if (this.pageService.modelDto.bia.biaResources == null) {
      return;
    }
    
    for (let index = 1; index <= this.pageService.modelDto.bia.biaResources.length; index++) {
      this.optionsSortOrder.push(new LookupValue(index.toString(), index.toString()));      
    }
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    if (this.paramItem.recoveryPriority != null) {
      this.recoveryPriority.setValue(this.paramItem.recoveryPriority.recoveryPriority);
    }
    
    this.resourceName.setValue(this.paramItem.resourceName);
    this.rto.setValue(this.paramItem.rto);
    this.rtodesc.setValue(this.paramItem.rtodesc);
    this.sortOrder.setValue(this.paramItem.sortOrder.toString());
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.sortOrder = +this.sortOrder.value;

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}

