import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IAppStepComponent } from '@app/_models/iappstep-component';
import { AppEditStepFormService } from '../app-edit/app-edit-step-form.service';
import { AppListPageService } from '../app-list/app-list-page.service';

@Component({
  selector: 'app-app-basic-info',
  templateUrl: './app-basic-info.component.html',
  styleUrls: ['./app-basic-info.component.scss'],
  providers: [ AppEditStepFormService ]
})
export class AppBasicInfoComponent implements OnInit, IAppStepComponent {

  pageIndex = 1;
  isSubmitted = false;
  saving = false;

  applicationForm!: UntypedFormGroup;
    
  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public stepService: AppEditStepFormService,
    public pageService: AppListPageService) { }

  ngOnInit(): void {

    this.applicationForm = this.formBuilder.group({
      appName: ['', Validators.required],
      appShortDesc: [''],
      appLongDesc: [''],
      appVersion: [''],
    });

    this.stepService.setComponent(this);
    this.loadPage();
  }

  public loadPage(): void {
    //load basic section:
    this.appName.setValue(this.pageService.modelDto.application.appName);
    this.appShortDesc.setValue(this.pageService.modelDto.application.appShortDesc);
    this.appLongDesc.setValue(this.pageService.modelDto.application.appLongDesc);
    this.appVersion.setValue(this.pageService.modelDto.application.appVersion);
  }

  get appName(): any {
    return this.applicationForm.get('appName');
  }

  get appShortDesc(): any {
    return this.applicationForm.get('appShortDesc');
  }

  get appLongDesc(): any {
    return this.applicationForm.get('appLongDesc');
  }

  get appVersion(): any {
    return this.applicationForm.get('appVersion');
  }


  public isValid(): boolean {
    return this.applicationForm.valid;
  }

  public loadDto(): void {
    this.pageService.modelDto.application.appName = this.appName.value;
    this.pageService.modelDto.application.appShortDesc = this.appShortDesc.value;
    this.pageService.modelDto.application.appLongDesc = this.appLongDesc.value;
    this.pageService.modelDto.application.appVersion = this.appVersion.value;
  }

}
