import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@app/_services/config.service';
import { UserService } from '@app/_services/user.service';
import { BIAService } from '@app/bia/_services/bia.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {  
  rptParameter: string = '';

  constructor(private router: Router,
    public userService: UserService,
    private biaService: BIAService) { }

  ngOnInit(): void {
    this.biaService.currentRptParameter.subscribe(parameter => {
      //Switch SSRS report URLs to PBI Url settings:
      this.rptParameter = ',' + parameter;

      if (this.rptParameter === ',') {
        this.rptParameter = '(1)';
      } else {
        this.rptParameter = ('(' + this.rptParameter + ')').replace('(,', '(');
      }

      //Comment out SSRS report URL settings:
      //this.rptParameter = '&BIA=' + parameter;

      //if (this.rptParameter === '&BIA=') {
      //  this.rptParameter = '';
      //}
    });

  }

  navigateApplication(): void {
    void this.router.navigate(['app-list']);
  }

  public get reportUrl(): string {
    return APP_CONFIG.reportServerUrl;
  }

  public get reportUrl2(): string {
    return APP_CONFIG.reportServerUrl2;
  }

  navigateReport(): void {
    //window.open(APP_CONFIG.reportServerUrl + this.rptParameter, '_blank', 'noopener');

    const updatedUrl = APP_CONFIG.reportServerUrl.replace('(1)', this.rptParameter);
    window.open(updatedUrl, '_blank', 'noopener');
  }

  navigateReport2(): void {
    //window.open(APP_CONFIG.reportServerUrl2 + this.rptParameter, '_blank', 'noopener');

    const updatedUrl2 = APP_CONFIG.reportServerUrl2.replace('(1)', this.rptParameter);
    window.open(updatedUrl2, '_blank', 'noopener');
  }

  navigateAdmin(): void {
    void this.router.navigate(['user-role-list']); 
  }

  navigateBia(): void {
    void this.router.navigate(['bia-list']); 
  }

  navigateReports(): void {

  }

  public isAdmin(): boolean {
    return this.userService.isAdmin;
  }
}
