import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BIADependencyModel } from '@app/bia/_models/bia-dependency-model';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-dependency-edit',
  templateUrl: './bia-dependency-edit.component.html',
  styleUrls: ['./bia-dependency-edit.component.scss']
})
export class BiaDependencyEditComponent implements OnInit {

  paramItem: BIADependencyModel = new BIADependencyModel();
  onUpdateItem: Subject<BIADependencyModel> = new Subject<BIADependencyModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Application Dependency';

  biaForm!: UntypedFormGroup;

  optionsCategory: Array<any> = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      applicationName: ['', Validators.required],
      applicationDesc: [''],
      backupPlan: [Boolean],
      downtimeDesc: [''],
      dataAgeDesc: [''],
    });
    
    this.loadPage();
  }

  get applicationName(): any {
    return this.biaForm.get('applicationName');
  }

  get applicationDesc(): any {
    return this.biaForm.get('applicationDesc');
  }

  get backupPlan(): any {
    return this.biaForm.get('backupPlan');
  }

  get downtimeDesc(): any {
    return this.biaForm.get('downtimeDesc');
  }

  get dataAgeDesc(): any {
    return this.biaForm.get('dataAgeDesc');
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.applicationName.setValue(this.paramItem.applicationName);
    this.applicationDesc.setValue(this.paramItem.applicationDesc);
    this.backupPlan.setValue(this.paramItem.backupPlan);
    this.downtimeDesc.setValue(this.paramItem.downtimeDesc);
    this.dataAgeDesc.setValue(this.paramItem.dataAgeDesc);

    if (this.paramItem.otherRequirement) {
      this.modalTitle = 'Other Dependency'
    }
    
    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.applicationName = this.applicationName.value;
    this.paramItem.applicationDesc = this.applicationDesc.value;
    this.paramItem.backupPlan = this.backupPlan.value;
    this.paramItem.downtimeDesc = this.downtimeDesc.value;
    this.paramItem.dataAgeDesc = this.dataAgeDesc.value;

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}

