import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployeeModel } from '@app/_models/employee-model';
import { LookupValue } from '@app/_models/lookup-value';
import { UserRoleModel } from '@app/_models/user-role-model';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, Subject } from 'rxjs';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';
import { UserRolePageService } from '../user-role-list/user-role-page.service';

@Component({
  selector: 'app-user-role-edit',
  templateUrl: './user-role-edit.component.html',
  styleUrls: ['./user-role-edit.component.scss']
})
export class UserRoleEditComponent implements OnInit {

  paramItem: UserRoleModel = new UserRoleModel();
  onUpdateItem: Subject<UserRoleModel> = new Subject<UserRoleModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'User';

  biaForm!: UntypedFormGroup;

  optionsRoleType: Array<any> = new Array<any>();
  optionsUser: Observable<any[]> = new Observable<any[]>();

  employeeList: Array<EmployeeModel> = new Array<EmployeeModel>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    public pageService: UserRolePageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      roleTypeId: ['', Validators.required],
      empId: ['', Validators.required]
    });

    this.loadRoleTypeList();
    this.loadPage();
  }

  get roleType(): any {
    return this.biaForm.get('roleTypeId');
  }

  get user(): any {
    return this.biaForm.get('empId');
  }

  loadRoleTypeList(): void {
    this.pageService.modelDto.userRoleTypes.forEach(item => {
      this.optionsRoleType.push(new LookupValue(item.roleTypeId.toString(), item.roleTypeNm))     
    });
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.roleType.setValue(this.paramItem.roleTypeId.toString());

    if (this.paramItem.employee != null) {
      this.user.setValue(this.paramItem.employee.fullName);
    }

    if (this.paramItem.userRoleId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
      this.user.disable();
    }
  }

  onEmployeeSelect(): void {
    
    const pp = this.modalService.show(EmployeePickerComponent, {
      class: 'modal-md',
      data: {
        lookupValueType: 'employee',
      },
    });

    pp.content.returnElements.subscribe((elements: any[]) => {
      elements.forEach(element => {

        this.paramItem.empId = element.value;
        this.user.setValue(element.label);

        this.paramItem.employee = element.employee;
        this.paramItem.employee.empId = element.value;
        this.paramItem.employee.fullName = element.label;
      });
    });
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.roleTypeId = +this.roleType.value;

    this.pageService.modelDto.userRoleTypes.forEach(item => {
      if (item.roleTypeId == this.paramItem.roleTypeId) {
        this.paramItem.roleType = item;
      }
    });

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}
